.wrapper {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background: #000;
  transition: all 0.6s ease-in-out;
}
#active:checked ~ .wrapper {
  right: 0;
}
.menu-btn {
  position: absolute;
  z-index: 2;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.menu-btn span,
.menu-btn:before,
.menu-btn:after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 100%;
  width: 50%;
  border-bottom: 3px solid #fff;
  border-radius: 10px;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.menu-btn:before {
  transform: translateY(-8px);
}
.menu-btn:after {
  transform: translateY(8px);
}

.close {
  z-index: 1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: background 0.6s;
}

#active:checked + .menu-btn span {
  transform: scaleX(0);
}
#active:checked + .menu-btn:before {
  transform: rotate(45deg);
  border-color: #fff;
}
#active:checked + .menu-btn:after {
  transform: rotate(-45deg);
  border-color: #fff;
}
.wrapper ul {
  position: absolute;
  top: 55%;
  left: 50%;
  height: 90%;
  width: 100%;
  transform: translate(-50%, -50%);
  list-style: none;
  text-align: center;
}
.wrapper ul li {
  height: 10%;
  margin: 15px 0;
  transition: all 0.2s;
}
.wrapper ul li a {
  text-decoration: none;
  font-size: 27px;
  font-weight: 500;
  color: #fff;
  border-radius: 50px;
  position: absolute;
  line-height: 50px;
  opacity: 0;
  transition: all 0.2s;
}
.wrapper ul li a:hover {
  color: #DEAEF9;
  font-size: 30px;
}
input[type="checkbox"] {
  display: none;
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  width: 100%;
  color: #202020;
}
.content .title {
  font-size: 40px;
  font-weight: 700;
}
.content p {
  font-size: 35px;
  font-weight: 600;
}

#active:checked ~ .wrapper ul li a {
  opacity: 1;
}
.wrapper ul li a {
  transition: opacity 1.2s, transform 1.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(100px);
}
#active:checked ~ .wrapper ul li a {
  transform: none;
  transition-timing-function: ease, cubic-bezier(0.1, 1.3, 0.3, 1);
  transition-delay: 0.6s;
  transform: translateX(-100px);
}

.icon_media {
  margin: 4%;
  width: 50px;
}

.mobile_menu {
    display: none;
}

.media_icons_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1360px) {
    .mobile_menu {
        display: flex;
    }
}