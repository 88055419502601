.location {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin-bottom: 6%;
}

.loc_back {
    background-image: url(../../assets/images/location.jpg);
}

.location h2 {
    font-size: 80px;
    color: #fff;
    font-weight: 900;
    padding-bottom: 2%;
}

.location p {
    text-align: center;
    display: inline-block;
    width: 50%;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
}

.location_text {
    font-size: 25px;
    line-height: 1.5;
    font-weight: 500;
    margin: 0 7% 3%;
    font-family: 'Merriweather', serif;
}

.location_image_block {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2%;
    justify-content: center;
}

.location_image_block img {
    width: 33.33%;
    /* width: 416px; */
    /* transition: all 0.2s; */
}

/* .location_image_block img:hover {
    transform: scale(1.099);
    box-shadow: 1px 3px 18px 3px rgba(13, 28, 39, 0.2);
} */

@media (max-width: 680px) {
    .location h2 {
        font-size: 75px;
    }

    .location p {
        font-size: 26px;
    }

    .location_text {
        font-size: 22px;
        margin: 0 2% 7%;
    }

    .location_image_block img {
        width: 50%;
    }
}

@media (max-width: 430px) {
    .location h2 {
        font-size: 50px;
    }

    .location p {
        font-size: 22px;
        width: 80%;
    }

    .location_text {
        font-size: 19px;
        margin: 0 2% 7%;
    }

    .location_image_block img {
        width: 50%;
    }
}