.article {
  font-size: 40px;
  padding: 45px;
  color: #7d006a;
  text-align: center;
}

.program_content {
}

.program_block_wrapper {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto 7%;
  padding: 20px;
}

.program_block_img {
  width: 250px;
  margin-right: 5%;
}

.program_content h3 {
  font-size: 26px;
  margin-bottom: 2%;
}

.program_content ul li {
  font-size: 20px;
  line-height: 1.8;
  list-style-type: "🌀";
  padding-left: 1%;
  font-family: "Merriweather", serif;
  font-weight: 500;
}

@media (max-width: 960px) {
  .program_block_wrapper {
    flex-direction: column;
    margin: 0 auto 15%;
  }

  .program_block_img {
    margin: 0 0 2%;
  }
}

@media (max-width: 680px) {
  .article {
    font-size: 35px;
  }

  .program_block_img {
    margin: 0 0 6%;
  }
}

@media (max-width: 430px) {
  .article {
    font-size: 30px;
  }

  .program_block_img {
    width: 220px;
  }

  .program_content h3 {
    margin-bottom: 6%;
  }

  .program_block_wrapper {
    width: 89%;
  }

  .program_content ul li {
    font-size: 18px;
  }
}

@media (max-width: 380px) {
  .article {
    font-size: 27px;
  }

  .program_block_img {
    width: 210px;
  }

  .program_content h3 {
    margin-bottom: 6%;
    font-size: 23px;
  }

  .program_block_wrapper {
    width: 94%;
  }

  .program_content ul li {
    font-size: 18px;
  }
}
