.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 130px;
    background-color: #917FA1;
    margin-top: 5%;
}

.footer p {
    color: #fff;
    font-size: 19px;
}

.footer_contacts {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_contacts a {
    font-size: 19px;
    text-decoration: none;
    color: #FEEBFF;
    margin-top: 10%;
    padding: 0 5% 0;
    transition: all 0.2s;
}

.footer_contacts a:hover {
    color: pink;
}

.footer_line {
    color: #fff;
}

.footer_contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (max-width: 430px) {
    .footer {
        height: 110px;
    }

    .footer p {
        font-size: 17px;
    }

    .footer_contacts a {
        font-size: 16px;
    }
}