* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Philosopher', sans-serif;
}

html {
    scroll-behavior: smooth;
    font-family: 'Philosopher', sans-serif;
}

body {
    background-color: #EEE6FF;
}

.container {
    width: 85%;
    margin: 0 auto;
}

@media (max-width: 680px) {

    .container {
        width: 90%;
    }
}