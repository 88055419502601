.accomm_back {
    background-image: url(../../assets/images/accomm_images/accomm_img_1.PNG);
}

.accommod_block {
    width: 75%;
    margin: 0 auto;
}

.accommod_article {
    font-size: 40px;
    padding: 5% 0;
    text-align: center;
}

.accommodation_article {
    font-size: 30px;
    padding-bottom: 3%;
    text-align: center;
    color: #3E0035;
}

.accommod_text {
    line-height: 1.4;
    margin-bottom: 5%;
    font-family: 'Merriweather', serif;
    font-size: 25px;
    line-height: 1.5;
    font-weight: 500;
}

@media (max-width: 680px) {
    .accommod_text {
        font-size: 23px;
    }
    
    .accommodation_article {
        font-size: 28px;
        padding-bottom: 2%;
    }

    .accommod_block {
        width: 87%;
    }
}

@media (max-width: 430px) {
    .accommod_text {
        font-size: 19px;
    }

    .accommodation_article {
        font-size: 23px;
        padding-bottom: 7%;
    }

    .accommod_block {
        width: 90%;
    }
}