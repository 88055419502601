.price_main {
  width: 50%;
  margin: 0 auto;
  background-color: #f3f3f3;
  border-radius: 20px;
  box-shadow: 0px 0px 15px -6px rgba(34, 60, 80, 0.55);
}

.price_content {
  padding: 35px 35px 10px;
}

.price_main h3 {
  font-size: 26px;
  margin-bottom: 8%;
  color: #9d2c5f;
}

.price_main p {
  text-align: center;
  padding-top: 20px;
  font-size: 19px;
}

.price_main li {
  list-style-image: url(https://uploads-ssl.webflow.com/5c1669435ba956bdb9b58d6e/5c49c2cdef5a1c331978579a_check.svg);
  font-size: 19px;
  margin: 5px 10px 19px;
  padding-left: 10px;
}

.price {
  display: flex;
  background: aliceblue;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.price .price_text_wrapper {
  display: flex;
  flex-direction: column;
  height: 85px;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
}

.price_btn {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  text-decoration: none;
  color: #000;
  background-color: #f7aaff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.2s;
}

.price_btn:hover {
  background-color: #b27bb8;
}

#red_text {
  color: #9d2c5f;
  text-decoration: line-through;
  font-size: 24px;
}

.price .text {
  font-size: 20px;
}

.price .text_price {
  font-size: 25px;
}

.ver_line {
  width: 1px;
  height: 70px;
  margin-top: 2px;
  background-color: grey;
}

.table_article {
  font-size: 25px;
  margin: 1.5%;
}

.add_services {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto 5%;
}

.add_services td,
.add_services th {
  border: 1px solid #ddd;
  padding: 15px;
  font-size: 19px;
}

.add_services tr:nth-child(even) {
  background-color: #f2f2f2;
}

.add_services th {
  font-size: 20px;
  text-align: left;
  background-color: #e165a5;
  color: white;
}

@media (max-width: 960px) {
  .price_main {
    width: 80%;
  }
}

@media (max-width: 430px) {
  .price_main {
    width: 100%;
  }

  .table_article {
    font-size: 22px;
    margin: 4%;
  }

  .add_services td {
    padding: 14px;
    font-size: 17px;
  }

  .add_services th {
    font-size: 19px;
  }

  .add_services {
    margin: 0 auto 12%;
  }
}

@media (max-width: 380px) {
  .price_content {
    padding: 15px 20px 10px;
}

.price_main h3 {
  font-size: 22px;
}

.price_main li {
  font-size: 17px;
}

.price .text {
  font-size: 18px;
}
}