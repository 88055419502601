.about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 55vh;
  background-image: url(../../assets/images/masters.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -250px;
  margin-bottom: 6%;
}

.about h2 {
  font-size: 80px;
  color: #fff;
  font-weight: 900;
  padding-top: 13%;
  text-shadow: 2px 0px 10px rgba(0, 0, 0, 0.72);
}

.about_block_wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 85%;
  margin: 0 auto;
}

.about_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  margin-bottom: 5%;
}

.about_block img {
  width: 500px;
  border-radius: 3px;
  box-shadow: -8px 13px 43px -9px rgba(0, 0, 0, 0.68);
}

.about_block h4 {
  font-size: 47px;
  font-weight: 900;
  margin: 4% 0;
}

.about_block p {
  display: inline-block;
  font-size: 22px;
  font-family: "Merriweather", serif;
  line-height: 1.4;
  /* width: 500px; */
}

.about_photos img {
  width: 33.3%;
}

.about_line {
  width: 100px;
  height: 2px;

  translate: 50%;
}

.about_symbol {
  font-size: 35px;
}

@media (max-width: 1388px) {
  .about_block_wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1360px) {
  .about {
    background-position: 0 -100px;
  }
}

@media (max-width: 960px) {
  .about_block_wrapper {
    flex-direction: column;
    align-items: center;
  }

  .about_block {
    margin-bottom: 15%;
  }
}

@media (max-width: 680px) {
  .about {
    background-position: 0 -100px;
    height: 30vh;
  }

  .about h2 {
    font-size: 70px;
    padding: 0;
  }

  .about_block_wrapper {
    justify-content: center;
    align-items: center;
  }

  .about_block {
    margin-bottom: 20%;
    width: 90%;
  }

  .about_block img {
    width: 470px;
  }

  .about_block h4 {
    font-size: 40px;
  }

  .about_block p {
    font-size: 20px;
  }

  .about_photos img {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 590px) {
  .about_block img {
    width: 360px;
  }

  .about_block h4 {
    font-size: 34px;
    text-align: center;
  }

  .about_block p {
    font-size: 19px;
  }

  .about_block {
    width: 100%;
  }
}

@media (max-width: 430px) {
  .about {
    background-position: 0 -50px;
    height: 25vh;
  }

  .about h2 {
    font-size: 50px;
  }

  .about_block h4 {
    font-size: 25px;
    text-align: center;
  }

  .about_block img {
    width: 305px;
  }

  .about_block p {
    font-size: 18px;
  }
}
