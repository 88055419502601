.intro_block {
    margin-top: 3%;
}

.intro_text {
    text-align: center;
    font-size: 28px;
    font-family: 'Merriweather', serif;
    line-height: 1.5;
    font-weight: 500;
    margin: 3% 8%;
}

.iframe_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6% 0;
}

.intro_small_text_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #917FA1;
}

.align_center {
    text-align: center;
}

.intro_small_text {
    text-align: center;
    font-size: 35px;
    line-height: 1.5;
    font-weight: 500;
    margin: 3% 20% 3%;
    font-family: 'Lobster', cursive;
    color: #fff;
}

.intro_small_text span {
    font-family: 'Merriweather', serif;
    font-size: 25px;
}

.intro_svg {
    fill: #7D006A;
    width: 110px;
}

@media (max-width: 850px) {
    video, iframe {
        height: 360px;
    }
}

@media (max-width: 690px) {
    video, iframe {
        height: 280px;
    }
}

@media (max-width: 500px) {
    video, iframe {
        height: 225px;
    }
}

@media (max-width: 400px) {
    video, iframe {
        height: 200px;
    }
}

@media (max-width: 400px) {
    video, iframe {
        height: 170px;
    }
}

@media (max-width: 1130px) {
    .align_center {
        text-align: initial;
    }

    .intro_text {
        text-align: center;
        font-size: 20px;
        font-family: 'Merriweather', serif;
        line-height: 1.5;
        font-weight: 500;
        margin: 10% 0;
    }

    .intro_small_text {
        font-size: 27px;
        margin: 10% 5%;
    }

    .iframe_wrapper {
        margin: 15% 0;
    }
    
    .bottom_svg {
        margin-bottom: 5%;
    }

    .intro_svg {
        width: 90px;
    }
}